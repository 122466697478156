* {
  box-sizing: border-box;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #4f6367;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header h1 {
  font-size: 50px;
  margin: 40px 20px;
  color: white;
}

.app-footer {
  background-color: #7a9e9f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: white;
}

.banxware-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  flex: 1;
}

.iframe-container {
  flex: 2;
}

.list-container {
  flex: 1;
  background-color: #eef5db;
  padding: 20px;
  color: darkslategray;
  font:
    0.7rem Inconsolata,
    monospace;
}

.iframe {
  height: 100%;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
}
